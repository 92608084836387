import { createPopper } from '@popperjs/core'

export default {
  methods: {

    isMobile () {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    },

    scaleOnMobile (a, b) {
      if (this.isMobile()) {
        return Number(a.mobile) - Number(b.mobile)
      }
      return Number(b.index) - Number(a.index)
    },

    removeSelectedOnMobile (row, _key) {
      return this.isMobile() ? row.key !== 'selected' : true
    },

    tableRowClass (row, selector) {
      if (row && selector === 'row') {
        return row.last_seen ? 'table-seen-request' : 'table-unseen-request'
      }
      return ''
    },

    tableRowNumber (table, index) {
      return Number(table.currentPage) * Number(table.perPage) - Number(table.perPage) + (Number(index) + 1)
    },

    iconState (icon, isActive) {
      return `${icon}-${isActive ? 'fill' : 'line'}`
    },

    statusByVariant (status = 'Pending', prefix = null) {
      switch (status) {
        case 'Pending': {
          return prefix ? `${prefix}-dark` : 'dark'
        }
        case 'Processing': {
          return prefix ? `${prefix}-warning` : 'warning'
        }
        case 'Disapproved': {
          return prefix ? `${prefix}-danger` : 'danger'
        }
        case 'Canceled': {
          return prefix ? `${prefix}-danger` : 'danger'
        }
        default: {
          return prefix ? `${prefix}-success` : 'success'
        }
      }
    },

    generateId (length) {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'

      let result = ''

      const charactersLength = characters.length

      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
      }

      return result
    },

    swalConfirm (options = {
      title: 'Confirmation',
      html: 'Would you like to save this record?',
      confirmButtonText: 'Save',
      cancelButtonText: 'Cancel',
      preConfirm: null,
      customClass: {
        confirmButton: 'btn btn-sm btn-success'
      }
    }) {
      return this.$swal.fire({
        title: options?.title || 'Confirmation',
        html: typeof options?.html === 'function' ? options?.html() : options?.html,
        confirmButtonText: options?.confirmButtonText || 'Save',
        showCancelButton: true,
        cancelButtonText: options?.cancelButtonText || 'Cancel',
        showLoaderOnConfirm: true,
        allowEscapeKey: false,
        heightAuto: false,
        buttonsStyling: false,
        allowOutsideClick: false,
        preConfirm: () => {
          if (typeof options?.preConfirm === 'function') {
            return options?.preConfirm()
          }
        },
        customClass: {
          title: 'h5 p-2 text-warning border-bottom',
          htmlContainer: 'px-2 py-3 m-0',
          actions: 'col-12 d-flex justify-content-end p-2 border-top mx-0',
          cancelButton: 'btn btn-sm btn-outline-dark ml-1',
          confirmButton: 'btn btn-sm btn-success',
          ...options?.customClass
        },
        width: '25rem'
      })
    },

    swalConfirmWithRemarks (options = {
      title: 'Confirmation',
      html: 'Would you like to save this record?',
      confirmButtonText: 'Save',
      cancelButtonText: 'Cancel',
      customClass: {
        confirmButton: 'btn btn-sm btn-success'
      },
      preConfirm: null,
      remarksRequired: true,
      remarksMaxLength: 2500,
      width: '25rem'
    }) {
      return this.$swal.fire({
        title: options?.title || 'Confirmation',
        html: typeof options?.html === 'function' ? options?.html() : options?.html,
        input: 'textarea',
        inputLabel: 'Remarks',
        inputAutoFocus: true,
        inputAttributes: {
          rows: 10,
          name: 'remarks',
          autocapitalize: 'off',
          maxlength: options?.remarksMaxLength || 2500,
          placeholder: options?.remarksRequired ? 'enter remarks' : 'enter remarks ( optional )'
        },
        didOpen: () => {
          const textArea = document.getElementById('swal2-input')
          if (textArea) {
            textArea.onkeydown = element => {
              const remarks = element.target.value

              if (options?.remarksMaxLength) {
                if (remarks?.search(/\n/g)) {
                  element.target.value = remarks.replace(/\n/g, '')
                }
              }
            }

            textArea.onchange = element => {
              const remarks = element.target.value
              if (remarks?.length > 0) {
                this.$swal.resetValidationMessage()
              }
            }
          }
        },
        inputValidator: remarks => {
          return new Promise(resolve => {
            if (options?.remarksMaxLength) {
              if (remarks?.length > Number(options?.remarksMaxLength)) {
                return resolve(
                  `The remarks field must be atleast lesser than or equal to ${options?.remarksMaxLength} characters`
                )
              }
            }

            if (options?.remarksRequired) {
              if (remarks?.length <= 0) {
                return resolve(
                  'The remarks field is required'
                )
              }
            }

            return resolve()
          })
        },
        confirmButtonText: options?.confirmButtonText || 'Save',
        showCancelButton: true,
        cancelButtonText: options?.cancelButtonText || 'Cancel',
        showLoaderOnConfirm: true,
        allowEscapeKey: false,
        buttonsStyling: false,
        heightAuto: false,
        allowOutsideClick: false,
        preConfirm: remarks => {
          if (typeof options?.preConfirm === 'function') {
            this.$swal.disableInput()
            return options?.preConfirm(remarks)?.finally(() => {
              this.$swal.enableInput()
            })
          }
        },
        customClass: {
          title: 'h5 p-2 text-warning border-bottom',
          htmlContainer: 'px-2 py-3 m-0',
          input: 'swal-input my-1 mx-3',
          inputLabel: 'd-flex align-self-start justify-content-start font-weight-bold col-12 px-3',
          validationMessage: 'align-self-start justify-content-start bg-white px-2 my-0 text-danger',
          actions: 'col-12 d-flex justify-content-end p-2 border-top mx-0',
          cancelButton: 'btn btn-sm btn-outline-dark ml-1',
          confirmButton: 'btn btn-sm btn-success',
          ...options?.customClass
        },
        width: options?.width || '25rem'
      })
    },

    swalSuccess (html = 'Record has been saved', title = 'Successful') {
      return this.$swal.fire({
        title: title,
        html: typeof html === 'function' ? html() : html,
        confirmButtonText: 'Dismiss',
        allowEscapeKey: false,
        allowOutsideClick: false,
        buttonsStyling: false,
        heightAuto: false,
        customClass: {
          title: 'h5 p-2 text-success border-bottom',
          htmlContainer: 'px-2 py-3 m-0',
          actions: 'col-12 d-flex justify-content-end p-2 border-top mx-0',
          confirmButton: 'btn btn-sm btn-success'
        },
        width: '25rem'
      })
    },

    swalInvalid (html = '<h6>Your inputs seem to be incorrect or incomplete, please check and try again.</h6>', title = 'Validation Error') {
      return this.$swal.fire({
        title: title,
        html: typeof html === 'function' ? html() : html,
        confirmButtonText: 'Dismiss',
        allowEscapeKey: false,
        allowOutsideClick: false,
        buttonsStyling: false,
        heightAuto: false,
        customClass: {
          title: 'h5 p-2 text-danger border-bottom',
          htmlContainer: 'px-2 py-3 m-0',
          actions: 'col-12 d-flex justify-content-end p-2 border-top mx-0',
          confirmButton: 'btn btn-sm btn-danger'
        },
        width: '25rem'
      })
    },

    calculateDropPosition (dropDownList, component, { width }) {
      dropDownList.style.width = width
      const popper = createPopper(component.$refs.toggle, dropDownList, {
        // placement: this.placement,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1]
            }
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn ({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top'
              )
            }
          }
        ]
      })
      return () => popper.destroy()
    }
  }
}
